import React from 'react'
import SkinCareHeader from '../Components/SkinCareHeader'
import Footer from '../Components/Footer'

const SkinBlogs = () => {
  return (
    <>
      <SkinCareHeader/>
      <div className='topmargin'></div>
      <div className="container-fluid strips " id="strip" style={{ marginTop: '100px', display: 'flex', backgroundColor: "#d4296b", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-5" >
            <h3 className='mt-2 mb-2' style={{ textTransform: 'uppercase' }}>Blogs</h3>
          </div>
          <ul className="container mb-5" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/Dental_Care_Home" style={{ color: 'white' }}>Home</a></li>
          </ul>
        </div>
      </div>

      {/* blogs styles are in App.css */}
      
      <div className='container mt-5 mb-5'>    
          <div className='row'>
            <div className='col-md-4 col-sm-12 mt-2 mb-2'>
              <div className='card' id='BlogCard'>
                  <img src='iv-glutathione.jpg' alt='Image'></img>
                  <a href='What_are_the_Benefits_of_IV_Glutathione' style={{textDecoration:'none',color:'black'}}><h6 className='p-3 text-center'>What are the Benefits of IV Glutathione</h6></a>
              </div>
            </div>

            <div className='col-md-4 col-sm-12 mt-2 mb-2'>
              <div className='card' id='BlogCard'>
                  <img src='anti-aging-treatment.webp' alt='Image' style={{height:'230px'}}></img>
                  <a href='Top_Anti_Aging_Treatments_to_Try_in_2025_Lotus_Cosmetic_Clinic' style={{textDecoration:'none',color:'black'}}><h6 className='p-3 text-center'>Top Anti-Aging Treatments to Try in 2025 - Lotus Cosmetic Clinic</h6></a>
              </div>
            </div>

            <div className='col-md-4 col-sm-12 mt-2 mb-2'>
              <div className='card' id='BlogCard'>
                  <img src='cosmetictreatment.png' alt='Image'style={{height:'230px'}}></img>
                  <a href='Your_Ultimate_Guide_to_Safe_Cosmetic_Treatments_Lotus_Cosmetic_Clinic' style={{textDecoration:'none',color:'black'}}><h6 className='p-3 text-center'>Your Ultimate Guide to Safe Cosmetic Treatments - Lotus Cosmetic</h6></a>
              </div>
            </div>
            
            <div className='col-md-4 col-sm-12 mt-2 mb-2'>
              <div className='card' id='BlogCard'>
                  <img src='prp-facial.jpg' alt='prp treatment in lotus cosmetic clinic' style={{height:'230px'}}></img>
                  <a href='PRP_Treatment' style={{textDecoration:'none',color:'black'}}><h6 className='p-3 text-center'>PRP Treatment</h6></a>
              </div>
            </div>

          </div>
      </div>
      <Footer />
    </>
  )
}

export default SkinBlogs